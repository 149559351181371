<template>
  <div>
    <page-heading
      :heading="
        procedure.name + ' (' + (baseInfo.procedureUserOwner && baseInfo.procedureUserOwner.user ? baseInfo.procedureUserOwner.user.full_name : '') +  ')'
    ">
      <span slot="help"></span>
      <div slot="right"></div>
    </page-heading>

    <el-card
      v-if="baseInfo.stage.negotiations"
      style="display: inline-block"
      class="stage"
      shadow="always"
    >
      <stage-item
        style="display: inline-block"
        :stage="baseInfo.stage"

      ></stage-item>
    </el-card>

    <el-card style="margin-top: 30px">
      <div style="display: flex;justify-content: space-between">
        <div class="smz-card-name">Сводка</div>
        <div>
          <el-button
            v-if="!budgetLimitReached && $canAndRulesPass(baseInfo.procedureUserOwner, 'raise_rss_user.start_negotiations')"

            size="mini"
            type="success"
            :loading="loadingHeader"
            @click="setStatus(3)"
          >
            Отправить на согласование
          </el-button>
          <el-button
            v-if="$canAndRulesPass(baseInfo.procedureUserOwner, 'raise_rss_user.reject_negotiations')"
            size="mini"
            type="danger"
            :loading="loadingHeader"
            @click="setStatus(2)"
          >
            <span v-if="user.id === baseInfo.procedureUserOwner.user_id">Отозвать с согласования</span>
            <span v-else>Отправить на доработку</span>
          </el-button>
          <el-button
            v-if="$canAndRulesPass(baseInfo.procedureUserOwner, 'raise_rss_user.agree_negotiations')"
            size="mini"
            type="success"
            :loading="loadingHeader"
            @click="setStatus(4)"
          >
            Согласовать
          </el-button>
        </div>
      </div>
      <div>Обратите внимание, цифры в данной таблице формируются автоматически, на основании заполнения блока "Детали"</div>
      <table
        class="smz-base-table rss-summary-table"
        style="margin-top: 10px"
        v-loading="loadingHeader"
      >
        <thead>
        <tr>
          <th>Вид вознаграждения</th>
          <th>Количество сотрудников имеющих право на вознаграждение </th>
          <th>Количество сотрудников с запланированными вознаграждениями</th>
          <th>Общая сумма бюджета (месяц), руб</th>
          <th>Использовано на текущий момент, руб</th>
          <th>Доступно для использования, руб</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Повышение по результатам труда</td>
          <td>{{baseInfo.stats.users_count_can_bonus ? baseInfo.stats.users_count_can_bonus.raise : ''}}</td>
          <td>{{baseInfo.stats.users_count_plan_bonus ? baseInfo.stats.users_count_plan_bonus.raise : ''}}</td>
          <td rowspan="2">{{baseInfo.stats.total_budget | numberFormat}}</td>
          <td>{{baseInfo.stats.used_budget ? baseInfo.stats.used_budget.raise : '' | numberFormat}}</td>
          <td rowspan="2" :class="{'error-cell': budgetLimitReached}">{{baseInfo.stats.free_budget | numberFormat}}</td>
        </tr>
        <tr>
          <td>Единовременная премия по результатам труда</td>
          <td>{{baseInfo.stats.users_count_can_bonus ? baseInfo.stats.users_count_can_bonus.bonus : ''}}</td>
          <td>{{baseInfo.stats.users_count_plan_bonus ? baseInfo.stats.users_count_plan_bonus.bonus : ''}}</td>
          <td>{{baseInfo.stats.used_budget ? baseInfo.stats.used_budget.bonus : '' | numberFormat}}</td>
        </tr>
        </tbody>
      </table>

    </el-card>


    <el-card style="margin-top: 30px">
      <div class="smz-card-name">Детали</div>

      <el-table
        border
        :data="procedureUsers"
        empty-text="Нет пользователей"
        size="mini"
        v-loading="loadingUsers"
        :row-key="(row) => row.user_id"
        @sort-change="sortChanged($event, getProcedureUsers)"
      >
        <el-table-column fixed width="180" prop="full_name" label="ФИО сотрудника" sortable="custom" v-slot="scope">
          <div v-if="!scope.row.historyUser">Нет данных на дату процедуры</div>
          <div v-else>{{scope.row.historyUser.last_name}} {{scope.row.historyUser.first_name}} {{scope.row.historyUser.middle_name}}</div>
        </el-table-column>
        <el-table-column fixed width="150" prop="position" label="Должность" sortable="custom" v-slot="scope">
          <div v-if="!scope.row.historyUser">Нет данных на дату процедуры</div>
          <div v-else>{{scope.row.historyUser.position}}</div>
        </el-table-column>
        <el-table-column width="150" prop="direction" label="Дирекция" sortable="custom" v-slot="scope">
          <div v-if="!scope.row.historyUser">Нет данных на дату процедуры</div>
          <div v-else>{{scope.row.historyUser.direction}}</div>
        </el-table-column>
        <el-table-column width="80" prop="workshop" label="Цех" sortable="custom" v-slot="scope">
          <div v-if="!scope.row.historyUser">Нет данных на дату процедуры</div>
          <div v-else>{{scope.row.historyUser.workshop}}</div>
        </el-table-column>
        <el-table-column width="80" prop="band" label="Бенд" sortable="custom" v-slot="scope">
          <div v-if="!scope.row.historyUser">Нет данных на дату процедуры</div>
          <div v-else>{{scope.row.historyUser.band}}</div>
        </el-table-column>
        <el-table-column width="100" prop="current_salary" label="Текущий оклад" sortable="custom" v-slot="scope">{{+scope.row.current_salary|numberFormat}}</el-table-column>
        <el-table-column width="100" prop="min_range_pay" label="Min диапазона оплаты труда" sortable="custom"></el-table-column>
        <el-table-column width="100" prop="max_range_pay" label="Max диапазона оплаты труда" sortable="custom"></el-table-column>
        <el-table-column width="120" prop="current_position_in_range" label="Текущая позиция в диапазоне" sortable="custom"></el-table-column>
        <el-table-column width="100" prop="evaluate_final_efficiency_id" label="Итоговая оценка" sortable="custom" v-slot="scope">
          {{getEvaluationNameById(scope.row.evaluate_final_efficiency_id)}}
        </el-table-column>
        <el-table-column width="150" prop="min_percent_of_raise" label="Минимальный % повышения" sortable="custom"></el-table-column>
        <el-table-column width="150" prop="mid_percent_of_raise" label="Средний рекомендованный % повышения" sortable="custom"></el-table-column>
        <el-table-column width="150" prop="max_percent_of_raise" label="Максимальный % повышения" sortable="custom"></el-table-column>
        <el-table-column width="300" prop="notifications" label="Уведомление!" sortable="custom" v-slot="scope">
          <div class="raise-rss-notifications" :class="{'has-notification': scope.row.notifications && JSON.parse(scope.row.notifications).length}">
            <div v-for="notification in JSON.parse(scope.row.notifications)">
              {{notification}}
            </div>
          </div>
        </el-table-column>
        <el-table-column width="150" prop="suggested_percent_of_raise" label="Предлагаемый % повышения" sortable="custom" v-slot="scope">
          <click-to-edit
            v-model="scope.row.suggested_percent_of_raise"
            :disabled="procedureAgreed || (budgetLimitReached && !scope.row.suggested_percent_of_raise)"
            :placeholder="scope.row.suggested_percent_of_raise_placeholder ? scope.row.suggested_percent_of_raise_placeholder.toString() : ''"
            digits-only
            @input="saveProcedureUserDetails(scope.row)"
          ></click-to-edit>
        </el-table-column>
        <el-table-column width="150" prop="suggested_sum_of_raise" label="Предлагаемая сумма повышения, руб" sortable="custom" v-slot="scope">{{+scope.row.suggested_sum_of_raise|numberFormat}}</el-table-column>
        <el-table-column width="150" prop="can_get_bonus" label="Право на получение единовременной премии" sortable="custom" v-slot="scope">
          {{scope.row.can_get_bonus ? 'Да' : ''}}
        </el-table-column>
        <el-table-column width="150" prop="suggested_percent_of_ones_bonus" label="Предлагаемый % единовременной премии" sortable="custom" v-slot="scope">
          <click-to-edit
            v-model="scope.row.suggested_percent_of_ones_bonus"
            :disabled="procedureAgreed || scope.row.suggested_percent_of_ones_bonus_disabled || (budgetLimitReached && !scope.row.suggested_percent_of_ones_bonus)"
            :placeholder="scope.row.suggested_percent_of_ones_bonus_placeholder ? scope.row.suggested_percent_of_ones_bonus_placeholder.toString() : ''"
            digits-only
            @input="saveProcedureUserDetails(scope.row)"
          ></click-to-edit>
        </el-table-column>
        <el-table-column width="150" prop="suggested_sum_of_ones_bonus" label="Предлагаемая сумма единовременной премии (в годовом выражении)" sortable="custom" v-slot="scope">{{+scope.row.suggested_sum_of_ones_bonus|numberFormat}}</el-table-column>
        <el-table-column width="150" prop="total_percent_of_raise" label="Общий % повышения с учетом единовременной премии" sortable="custom" v-slot="scope">{{+scope.row.total_percent_of_raise|numberFormat}}</el-table-column>
        <el-table-column width="150" prop="current_salary_after_raise" label="Оклад после повышения, руб" sortable="custom" v-slot="scope">{{+scope.row.current_salary_after_raise|numberFormat}}</el-table-column>
        <el-table-column width="150" prop="position_of_current_salary_in_range" label="Позиция оклада в диапазоне" sortable="custom"></el-table-column>
        <el-table-column width="100" prop="hire_date" label="Дата приема" sortable="custom" v-slot="scope">
          <div v-if="!scope.row.historyUser">Нет данных на дату процедуры</div>
          <div v-else>{{new Date(scope.row.historyUser.hire_date).toLocaleDateString($companyConfiguration.helpers.getDateLocale())}}</div>
        </el-table-column>
        <el-table-column width="150" prop="location" label="Локация" sortable="custom" v-slot="scope">
          <div v-if="!scope.row.historyUser">Нет данных на дату процедуры</div>
          <div v-else>{{scope.row.historyUser.location}}</div>
        </el-table-column>
        <el-table-column width="350" prop="comments" label="Комментарий руководителя" sortable="custom" v-slot="scope">
          <click-to-edit
            v-model="scope.row.comments"
            :disabled="procedureAgreed"
            placeholder=""
            type="textarea"
            @input="saveProcedureUserDetails(scope.row)"
          ></click-to-edit>
        </el-table-column>
      </el-table>

      <paginator
        :paginator.sync="paginator"
        @page-changed="getProcedureUsers"
      ></paginator>

    </el-card>

  </div>
</template>

<script>

import requestSender from "@/api/base/requestSender";
import ClickToEdit from "@/components/ClickToEdit.vue";
import PageHeading from "@/components/PageHeading.vue";
import StageItem from "@/components/cards/CardStagesBlock/StageItem.vue";
import Paginator from "@/components/Paginator.vue";
import {paginator} from "@/mixins/paginator";
import {sorter} from "@/mixins/sorter";
import {mapGetters} from "vuex";

export default {
  name: "RaiseRssTemplate",
  components: {Paginator, StageItem, PageHeading, ClickToEdit},
  mixins:[paginator, sorter],
  props: {
    procedure: {type: Object, required: true},
  },

  computed: {
    ...mapGetters(['user']),
    budgetLimitReached() {
      return this.baseInfo.stats.free_budget < -50
    },
    procedureAgreed() {
      return this.baseInfo.procedureUserOwner.agree_status === 4;
    },
  },
  data() {

    return {
      loadingHeader: false,
      loadingUsers: false,

      evaluations: [],

      baseInfo:{
        stage: {},
        stats: {},
        procedureUserOwner: [],
      },


      procedureUsers: [],


    }
  },

  mounted() {
    this.getEvaluations();
    this.getBaseInfo();
    this.getProcedureUsers();
  },
  methods: {
    getEvaluations() {
      requestSender('get', 'evaluation/search')
        .then(({evaluations}) => {
          this.evaluations = evaluations;
        })
    },

    getEvaluationNameById(evaluationId){
      return this.evaluations[4]?.find(e=>e.id===evaluationId)?.name;
    },

    getBaseInfo() {
      this.loadingHeader = true;

      requestSender('get', 'compensation-procedure-raise-rss/get-base-info', {
        procedure_id: this.procedure.id,
        user_id: parseInt(this.$route.params.owner_id),
        expand: 'user, permissions',
      })
        .then(data => {
          this.baseInfo.procedureUserOwner = data.procedure_user_owner;
          this.baseInfo.stats = data.stats;
          this.baseInfo.stage = data.stage;

          if( this.budgetLimitReached ){
            this.$notify.warning({
              title: 'Бюджет превышен!',
              message: 'Скорректируйте внесенные проценты!'
            });
          }
        })
        .finally(() => {
          this.loadingHeader = false;
        });
    },

    getProcedureUsers() {
      this.loadingUsers = true;

      requestSender('get', 'compensation-procedure-raise-rss/get-employee-procedure-users', {
        procedure_id: this.procedure.id,
        owner_id: parseInt(this.$route.params.owner_id),
        expand: 'historyUser',

        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
        .then(({data, paginator, sorter}) => {
          this.procedureUsers = data.procedure_users;

          this.sorterFill(sorter);
          this.paginatorFill(paginator);
        })
        .finally(() => {
          this.loadingUsers = false;
        });
    },

    saveProcedureUserDetails(procedureUser){
      this.loadingUsers = true;

      requestSender('post', 'compensation-procedure-raise-rss/save-procedure-user-details', {
        procedure_id: this.procedure.id,
        user_id: procedureUser.user_id,
        suggested_percent_of_raise: procedureUser.suggested_percent_of_raise,
        suggested_percent_of_ones_bonus: procedureUser.suggested_percent_of_ones_bonus,
        comments: procedureUser.comments,
      })
        .finally(() => {
          this.getBaseInfo();
          this.getProcedureUsers();
        });
    },

    setStatus(status){
      this.loadingHeader = true;

      requestSender('post', 'compensation-procedure-raise-rss/set-status', {
        procedure_id: this.procedure.id,
        owner_id: parseInt(this.$route.params.owner_id),
        status: status,
      })
        .finally(() => {
          this.getBaseInfo();
          this.getProcedureUsers();
        });
    },

  },


}
</script>

<style lang="scss">
.raise-rss-notifications.has-notification{
  padding: 3px;
  background-color: #daaf45;
}

.rss-summary-table .error-cell{
  background-color: #fac8c8;
}
</style>